import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTooth, faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

const Patient = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DDSLocator Dentist Reviews | Finding The Best Dentist For You"
          description="Find the best dentist in your area to treat your unique oral health care situation. Find dentists in your area, learn about their specialties and services and read dentist reviews from other patients. "
        />
        <Container fluid={true}>
          <Row>
            <Col md="12">
              <div className="main-image mt-4">
                <StaticImage
                  src="../images/DDSLocator-header-2500x651.jpg"
                  alt="Dentists"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="mb-5">
          <Row>
            <Col md="12">
              <h1 className="mb-3 mt-5 text-center title">
                How to Find the Best Dentist for You
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">1. Find a Dentist</h2>
              <p className="text-center">Search for dentists in your area</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faSearch} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">2. Choose a Dentist</h2>
              <p className="text-center">View profile, services and reviews</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faTooth} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">3. Contact a Dentist</h2>
              <p className="text-center">Complete form to contact dentist</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faPhone} size="5x" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="mt-4">
          <Row>
            <Col
              lg="12"
              className="flex justify-content-center align-content-center"
            >
              <div className="text-center purple-box">
                <div>
                  <p>
                    The best way to know if a dentist is right for you is to do
                    some quick research. It’s a good idea to ask family and
                    friends for a referral. Whether you have a referral or not,
                    it is recommended to look the dentist up online. On
                    DDSLocator, you can search by location, specialty and
                    services provided. Be sure to view the dentist profile where
                    you can learn about the dentist in more detail and browse
                    reviews from other patients. Once you have found a dentist
                    or two that look like a good fit, use the contact form to
                    ask additional questions or request an appointment. At the
                    initial consultation, observe how the dentist and staff
                    communicate, if the facility is clean and organized, and if
                    they are transparent with the costs associated with your
                    treatment plan to help make your final decision.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true}>
          <Row style={{ margin: `30px 0` }}>
            <Col xl="6" className="green-container-fix">
              <div className="green-box-left">
                <h2 style={{ color: `#fff` }}>
                  How do I find the best dentist for me?
                </h2>
                <ul style={{ color: `#fff` }}>
                  <li>Make sure the dental practice is near home or work</li>
                  <li>
                    Check the office hours to make sure they fit your schedule
                  </li>
                  <li>
                    Review the services provided to make sure they meet your
                    needs
                  </li>
                  <li>Look at reviews from other patients</li>
                  <li>
                    Verify that they have a proper infection control policy in
                    place
                  </li>
                  <li>Confirm if the practice accepts your insurance plan</li>
                  <li>See if the practice has financing options</li>
                  <li>
                    Ask if they discuss treatment plans up front and give clear
                    cost estimates
                  </li>
                  <li>
                    Explore the dentist’s schooling, association memberships and
                    specialty
                  </li>
                  <li>
                    View images of the location to feel comfortable with the
                    facility
                  </li>
                  <li>
                    Consider if the latest technology is available and important
                    to you
                  </li>
                  <li>
                    Observe how the office communicates and if it fits your
                    lifestyle
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl="6" className="gray-container-fix">
              <div className="gray-box">
                <h2 className="text-center">
                  What is the difference between a DDS and DMD?
                </h2>
                <p>
                  All practicing general dentists have earned either a DDS or
                  DMD degree (doctor of dental surgery or doctor of dental
                  medicine, respectively). There is no difference between the
                  two degrees or the curriculum requirements that dentists must
                  meet. Some schools simply award the one degree, while others
                  award the other.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="mt-5 mb-4 text-center">
          <Row className="mb-5">
            <Col md="12">
              <h2 className="text-center">
                What are some common dental specialties?
              </h2>
              <p className="text-center">
                Occasionally, your primary care dentist may refer you to a
                specialist. Below are some common dental specialities:
              </p>
            </Col>
          </Row>
          {/* First Row */}
          <Row className="mb-3">
            <Col lg="4">
              <h3>General Dentist</h3>
              <p>
                A primary care dental provider that diagnoses, treats, and
                manages your overall oral health care needs.{' '}
              </p>
            </Col>
            <Col lg="4">
              <h3>Pediatric Dentist</h3>
              <p>
                A primary care dental provider specializing in managing the
                overall oral health care of children, from infancy through the
                teenage years.
              </p>
            </Col>
            <Col lg="4">
              <h3>Cosmetic Dentist</h3>
              <p>
                A dentist that cares for your overall oral health and
                specializes in esthetic smile design, perfecting the smile with
                a variety of cosmetic restorative techniques.
              </p>
            </Col>
          </Row>
          {/* Second Row */}
          <Row>
            <Col lg="4" className="mb-3">
              <h3>Orthodontist</h3>
              <p>
                A dentist that specializes in correction of irregularities of
                the teeth, bite, and jaws, including crooked, crowded and
                misaligned teeth.
              </p>
            </Col>
            <Col lg="4">
              <h3>Endodontist</h3>
              <p>
                A dentist that specializes in the morphology, physiology and
                pathology of the human dental pulp and periradicular tissues,
                commonly performing root canal therapy.{' '}
              </p>
            </Col>
            <Col lg="4">
              <h3>Prosthodontist</h3>
              <p>
                A dentist that specializes in the restoration and replacement of
                broken teeth with crowns, bridges, removable prosthetics
                (dentures) and implant restorations.
              </p>
            </Col>
          </Row>
          {/* Third Row */}
          <Row>
            <Col lg="4">
              <h3>Periodontist</h3>
              <p>
                A dentist who specializes in the prevention, diagnosis, and
                treatment of periodontal disease, and in the placement of dental
                implants
              </p>
            </Col>
            <Col lg="4">
              <h3>Oral Surgeon</h3>
              <p>
                An oral and maxillofacial surgeon focuses on reconstructive
                surgery of the face, facial trauma surgery, the oral
                cavity, head and neck, mouth, and jaws, as well as
                facial cosmetic surgery.
              </p>
            </Col>
            <Col lg="4">
              <h3>Hygienist</h3>
              <p>
                A primary healthcare professional who works independently of or
                alongside dentists to provide full oral health care, including
                teeth cleaning and the prevention and treatment of many oral
                diseases.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="mb-5">
          <Row>
            <Col md="12">
              <h1 className="mt-5 mb-3 text-center">
                Find a Dentist with DDSLocator
              </h1>
              <p>
                Choosing a dentist is a personal decision and we encourage you
                to consult with several dentists to find a practice that meets
                the needs of your unique oral health situation. View our growing
                directory of select dentists that may be in your area.
              </p>
              <div className="button-container text-center">
                <Link to="/find-dentist" className="sub-button">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Patient
